.App {
  text-align: center;
  background-color: #2C2C2C;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-logo {
  width: 300px;
}
